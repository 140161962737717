import { Component, OnInit } from "@angular/core";
import { GiftService } from "../../services/gift.service";
import { AdminBaseComponent } from "@apps/modules/emlo-admin/abstract/admin.base.component";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { IdControl } from "@apps/modules/emlo-admin/shared/formfields/form-fields";
import { IconName } from "@apps/common/constants/icon";
import { ActivatedRoute, Router } from "@angular/router";
import { CredentialsService } from "@apps/core/authentication/credentials.service";
import { UserRoleTypeEnum } from "@apps/common/enums/role.type";
import { Gift } from "../../model/gift";
import { concat, Observable, of, Subject } from "rxjs";
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	switchMap,
	tap,
} from "rxjs/operators";
import { InputValueType } from "@apps/common/enums/input-value-type";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateUtils } from "@apps/core/utils/date";
import { environment } from "@env/environment";

@Component({
	selector: "app-gift-detail",
	templateUrl: "detail.html",
})
export class GiftDetailComponent extends AdminBaseComponent implements OnInit {
	tabIndex: number = 0;
	IconName = IconName;
	form = new FormGroup({});
	giftDetailFormFields: FormlyFieldConfig[] = [];
	giftDetail: Gift = new Gift();
	giftTypes$: Subject<any[]> = new Subject();
	items$: Observable<any>;
	itemsLoading = false;
	itemsInput$ = new Subject<string>();
	minLengthTerm = 3;
	menuItem: { id: string; name: string } = {
		id: null,
		name: null,
	};
	validFrom: NgbDateStruct;
	validTo: NgbDateStruct;
	termConditions: string;
	avatar: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private credentialsService: CredentialsService,
		private giftService: GiftService
	) {
		super();
	}

	ngOnInit(): void {
		this.canAccess = this.credentialsService.canAccess([
			UserRoleTypeEnum.Administrator,
			UserRoleTypeEnum.CustomerService,
		]);
		if (!this.canAccess) return;
		this.getGiftTypes();
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const giftId = params.get("id");
			if (giftId) {
				this.isEdit = true;
				this.getGiftById(giftId);
			}
			this.createForm();
		});
		this.fetchItemGift();
	}

	private fetchItemGift() {
		this.items$ = concat(
			of([]),
			this.itemsInput$.pipe(
				filter((res) => {
					return res !== null && res.length >= this.minLengthTerm;
				}),
				distinctUntilChanged(),
				debounceTime(500),
				tap(() => (this.itemsLoading = true)),
				switchMap((term) => {
					return this.giftService.getItemsGift(term).pipe(
						catchError(() => of([])), // empty list on error
						tap(() => (this.itemsLoading = false))
					);
				})
			)
		);
	}

	trackByFn(item: any) {
		return item.id;
	}

	private getGiftTypes(): void {
		this.giftService.getGiftTypes().subscribe((data) => {
			if (data) {
				// data.map(x => x.levelCode = x.id);
				this.giftTypes$.next(data);
			}
		});
	}

	private createForm() {
		this.giftDetailFormFields = [
			{
				fieldGroupClassName: "row",
				fieldGroup: [
					{
						type: "input",
						key: "name",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Name",
							placeholder: "Name",
						},
					},
					{
						key: "giftType",
						type: "emlo-select",
						className: "col-6",
						hideExpression: this.isEdit,
						templateOptions: {
							label: "Gift Type",
							placeholder: "Gift Type",
							bindLabel: "name",
							bindValue: "id",
							options: this.giftTypes$,
						},
					},
					{
						key: "giftTypeName",
						type: "input",
						className: "col-6",
						hideExpression: !this.isEdit,
						templateOptions: {
							label: "Gift Type",
							disabled: true,
							placeholder: "Gift Type",
						},
					},
					{
						key: "valueType",
						type: "radio",
						defaultValue: 1,
						className: "col-12",
						templateOptions: {
							label: "Value Type",
							options: [
								{ value: InputValueType.Fixed, label: "Fixed" },
								{
									value: InputValueType.Percent,
									label: "Percent",
								},
							],
						},
					},
					{
						type: "input",
						key: "amount",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Amount",
							placeholder:
								"Fix amount (not avaialble for Points & Discount",
							type: "number",
						},
						hideExpression: (
							model: Gift,
							formState: any,
							field: FormlyFieldConfig
						) => {
							if (
								model &&
								model.valueType === InputValueType.Percent
							) {
								return true;
							}
							return false;
						},
					},
					{
						type: "input",
						key: "amountPercent",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Amount by percentage",
							placeholder:
								"Amount by percentage (not avaialble for Points & Discount)",
							type: "number",
						},
						hideExpression: (
							model: Gift,
							formState: any,
							field: FormlyFieldConfig
						) => {
							if (
								model &&
								model.valueType === InputValueType.Fixed
							) {
								return true;
							}
							return false;
						},
					},
					{
						type: "input",
						key: "numberOfValidDays",
						defaultValue: null,
						className: "col-3",
						templateOptions: {
							label: "Number Of Valid Days",
							placeholder: "Number Of Valid Days",
							type: "number",
						},
					},
					{
						type: "input",
						key: "minExpense",
						defaultValue: null,
						className: "col-3",
						templateOptions: {
							label: "Minimum Expense",
							placeholder: "Minimum Expense",
							type: "number",
						},
					},
					//{
					//	type: "emlo-checkbox",
					//	key: "isPermanent",
					//	defaultValue: null,
					//	className: "col-3",
					//	templateOptions: {
					//		label: "Is Permanent",
					//		placeholder:
					//			"True in case gift will be always available when set to customer",
					//	},
					//},
					{
						type: "emlo-checkbox",
						key: "isDOBGift",
						defaultValue: null,
						className: "col-3",
						templateOptions: {
							label: "Is Gift for DOB",
							placeholder:
								"True in case gift will be set/check on DOB of customer",
						},
					},
					{
						type: "emlo-checkbox",
						key: "isExchangeable",
						defaultValue: null,
						className: "col-3",
						templateOptions: {
							label: "Can exchange from point",
							placeholder:
								"True in case gift could be used to exchange from points",
						},
					},
					{
						type: "input",
						key: "pointToExchange",
						defaultValue: null,
						className: "col-3",
						templateOptions: {
							label: "Point to exchange",
							placeholder: "Amount point to exchange",
							type: "number",
						},
						hideExpression:
							"model && (!model.isExchangeable || model.isExchangeable === false)",
					},
					{
						type: "input",
						key: "menuItemName",
						defaultValue: null,
						className: "col-6",
						templateOptions: {
							label: "Item Name",
							placeholder: "Item Name",
							readonly: true,
						},
						hideExpression: "model && model.giftType !== 3",
					},
					{
						type: "textarea",
						key: "termConditions",
						defaultValue: null,
						className: "col-12",
						templateOptions: {
							label: "Term Conditions",
							placeholder: "Input the term and conditions",
							type: "textarea",
						},
					},
				],
			},
			IdControl,
			{
				type: "emlo-input",
				key: "concurrencyStamp",
				templateOptions: {
					type: "hidden",
				},
			},
			{
				type: "emlo-input",
				key: "menuItemId",
				templateOptions: {
					type: "hidden",
				},
			},
		];
	}

	private parseDate(date: string): NgbDateStruct {
		if (!date) return null;
		const dateParts = date.trim().split("-");
		if (dateParts.length !== 3) return null;
		return {
			year: parseInt(dateParts[0]),
			month: parseInt(dateParts[1]),
			day: parseInt(dateParts[2]),
		};
	}

	private getGiftById(giftId: string): void {
		this.giftService.getGiftById(giftId).subscribe((gift: Gift) => {
			if (gift) {
				this.menuItem = {
					id: gift.menuItemId,
					name: gift.menuItemName,
				};
				this.giftDetail = gift;
				this.validFrom = this.parseDate(gift.validFrom);
				this.validTo = this.parseDate(gift.validTo);
				this.termConditions = gift.termConditions;
				this.avatar = gift.avatar;
				if (gift.avatar) {
					document.getElementById("image").style.display = "block";
					let avatar = document.querySelector(
						"#image img"
					) as HTMLImageElement;
					if (avatar) {
						this.giftService.getImage(gift.avatar).subscribe(
							(data) => {
								const blob = new Blob([data], {
									type: "image/jpeg",
								});
								const url = window.URL.createObjectURL(blob);
								avatar.src = url;
								avatar.alt = "Gift Image";
							},
							(error) => {
								console.error("No image found", error);
							}
						);
					}
				}
			}
		});
	}

	submit() {
		const data = this.form.value;
		if (!this.isEdit) {
			delete data.id;
		}
		data.menuItemId = this.menuItem ? this.menuItem.id : null;
		data.menuItemName = this.menuItem ? this.menuItem.name : null;
		data.validFrom = DateUtils.ConvertDateStructToDate(
			this.validFrom,
			"YYYY-MM-DD"
		);
		data.validTo = DateUtils.ConvertDateStructToDate(
			this.validTo,
			"YYYY-MM-DD"
		);
		data.minExpense = data.minExpense ? data.minExpense : 0;
		data.avatar = this.avatar;
		this.isEdit ? this.updateGift(data) : this.createGift(data);
		data.termConditions = this.termConditions;
	}

	private createGift(body: Gift): void {
		this.giftService.createGift(body).subscribe(() => {
			this.backToGiftList();
		});
	}

	private updateGift(body: Gift): void {
		this.giftService.updateGift(body).subscribe(() => {
			this.backToGiftList();
		});
	}

	cancel() {
		this.backToGiftList();
	}

	private backToGiftList(): void {
		this.router.navigate(["/customer-service/configuration/gift"]);
	}

	swittchTab(tabIndex: number) {
		this.tabIndex = tabIndex;
	}

	onChangeMenuItem(item: { id: string; name: string }) {
		if (item) {
			this.menuItem = item;
		} else {
			this.menuItem = {
				id: null,
				name: null,
			};
		}
	}

	uploadFile(event: any) {
		const file = event.target.files[0];
		document.getElementById("image").style.display = "block";
		let avatar = document.querySelector("#image img") as HTMLImageElement;
		if (avatar) avatar.src = URL.createObjectURL(file);

		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.giftDetail.avatar = reader.result.toString();
			};
		}

		const formData = new FormData();
		formData.append("file", file);

		fetch(environment.api_url + "/aapi/v1/giftdef/uploadfile", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${this.credentialsService.credentials.access_token}`,
			},
			body: formData,
		})
			.then((response) => response.json())
			.then((result) => {
				this.avatar = result.fileName;
				console.log(this.avatar);
				console.log("Success:", result);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}
}
